<template>
	<div>
		<Loader v-if="isLoading" />
		<div class="my-5">
			<Logo class="max-w-[100px] md:max-w-[150px] mx-auto" />
		</div>
		<router-view class="mx-auto px-3 sm:px-4 md:px-5 xl:px-10 max-w-[1400px]"></router-view>
	</div>
</template>
<script>
import "@/assets/styles/mo-global.scss";
import {mapGetters} from "vuex";
import Logo from "@/components/common/logo/TheDJCompany";
import Loader from "@/components/common/Loader";

export default {
	name: "customerEvaluationLayout",
	components: {
		Logo,
		Loader,
	},
	computed: {
		...mapGetters({
			isLoading: "getLoadingState",
		}),
	},
};
</script>
